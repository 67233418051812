<script setup lang="ts">
import { inject, onMounted, ref } from "vue"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps({
    system_coin: {
        type: String,
        required: true,
    },
    coins_availables: {
        type: Array<String>,
        default: [],
    },
})

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

// TODO check this
// const emitter = inject('emitter')
const coin = ref(props.system_coin)

/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(() => {
    // TODO check this
    // emitter.on('update:SystemCoin',()=>{
    //     coin.value = props.system_coin
    // })
})

/**
 *
 *  Functions section
 *
 *
 */

// TODO update this
// const change = (value:any) => {
//     router.post(route('set-system-coin'), {coin: value},
//     {
//         preserveScroll: true,
//         onSuccess: () => {
//             emitter.emit('update:SystemCoin')
//         }
//     })
// }
</script>
<template>
    <q-select
        v-model="coin"
        borderless
        :options-dark="false"
        options-dense
        :options="props.coins_availables"
        class="tw-coin-select-container"
        hide-dropdown-icon
    >
        <template #append>
            <q-icon
                name="keyboard_arrow_down"
                size="16px"
                class="tw-text-white"
            />
        </template>
    </q-select>
    <!-- TODO update this -->
    <!-- @update:model-value="change"  -->
</template>
<style>
.tw-coin-select-container {
    @apply tw-my-auto;
}

.tw-coin-select-container .q-field__control {
    @apply tw-gap-1;
}
.tw-coin-select-container .q-field__control-container,
.tw-coin-select-container .q-field__append,
.tw-coin-select-container .q-field__control,
.tw-coin-select-container .q-field__native {
    @apply tw-min-h-max tw-h-max tw-p-0 tw-my-auto;
}
.tw-coin-select-container .q-field__native {
    @apply tw-text-white tw-text-base tw-font-medium tw-leading-6;
}
</style>
