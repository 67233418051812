<script setup lang="ts">
import { useMenuItemSelectedStore } from "~/stores/MenuItemSelected"
import { useCategoriesStore } from "~/stores/categoriesStore"
import { useCurrentPageStore } from "~/stores/currentPageStore"
import { useShopListStore } from "~/stores/shopListStore"
import type { HomeBreadCrumbItem } from "~/components/HomeBreadCrumbs.vue"
import { useOFetchCustom } from "~/composables/useOFetchCustom"
import {
    type SUPPORTED_PAGES,
    SUPPORTED_PAGES_ENUM,
} from "~/common/breadCrumbs"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    title?: string
    withoutPreNav?: boolean
    withoutBanner?: boolean
    withoutBreadCrumbs?: boolean
    breadCrumbs?: HomeBreadCrumbItem[]
    currentPage?: SUPPORTED_PAGES
    lastBreadCrumbName?: string
    withoutParentStyles?: boolean
    parentClass?: string
    withoutSteps?: boolean
    customBanner?: string
    customBgBanner?: string
    bannerClass?: string
    withoutPreFooter?: boolean
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const router = useRouter()
const menuItemSelectedStore = useMenuItemSelectedStore()
const shopCartStore = useShopCartStore()
const initSettingsStore = useDefaultSettingsStore()
const shopListStore = useShopListStore()
const categoriesStore = useCategoriesStore()
const { data, get } = useOFetchCustom("init-settings")
const { breadCrumbs, addBreadCrumbsItem } = useBreadCrumbs(props.currentPage)
const currentPageStore = useCurrentPageStore()
currentPageStore.setCurrentPage(props.currentPage || SUPPORTED_PAGES_ENUM.Home)
const route = useRoute()
/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(async () => {
    if (route.path === "/") {
        categoriesStore.fetchCategories()
    }

    get(undefined, {
        onSuccess: (res) => {
            initSettingsStore.setSettings(res.data)
        },
    })

    shopListStore.fetchShopList()
    shopCartStore.fetchShopCart()
    const route_name = router.currentRoute.value.name

    if (route_name) {
        menuItemSelectedStore.setByRoute(route_name.toString())
    }
    if (props.breadCrumbs && props.breadCrumbs.length > 0) {
        breadCrumbs.value = props.breadCrumbs
    }

    if (props.lastBreadCrumbName) {
        addBreadCrumbsItem(props.lastBreadCrumbName)
    }
})

watch(
    () => props.lastBreadCrumbName,
    (newValue: any) => {
        if (newValue) {
            addBreadCrumbsItem(newValue)
        }
    }
)

watch(
    () => shopListStore.currentShop,
    (newValue) => {
        if (newValue) {
            categoriesStore.fetchCategoriesByStore(newValue.slug)
        } else {
            categoriesStore.fetchCategories()
        }
    },
    {
        flush: "post",
    }
)

/**
 *
 *  Functions section
 *
 *
 */
</script>

<template>
    <q-layout view="hHh lpR fff">
        <slot name="header">
            <q-header>
                <slot name="nav">
                    <HomeNav>
                        <template #pre-nav>
                            <HomePreNav v-if="!withoutPreNav" />
                        </template>
                        <template #mobile-left-drawer>
                            <slot name="mobileLeftDrawer"></slot>
                        </template>
                    </HomeNav>
                </slot>
            </q-header>
        </slot>
        <q-page-container>
            <q-page>
                <template v-if="!withoutBanner">
                    <slot name="banner">
                        <HomeBanner
                            :customBanner="props.customBanner"
                            :title="title || ''"
                            :customBgBanner="props.customBgBanner"
                            :withoutBackground="
                                !!bannerClass && bannerClass !== ''
                            "
                            :class="bannerClass"
                        />
                    </slot>
                </template>
                <template v-if="!withoutBreadCrumbs">
                    <HomeBreadCrumbs :items="breadCrumbs" />
                </template>
                <slot name="main">
                    <slot name="pre_main"></slot>
                    <div
                        :class="`${!withoutParentStyles && `${parentClass} tw-py-20 max-container`}`"
                    >
                        <slot name="offers"></slot>
                        <slot></slot>
                    </div>
                </slot>
                <slot name="post_main">
                    <slot name="most_sold"></slot>
                </slot>
            </q-page>
        </q-page-container>

        <footer class="footer">
            <HomePreFooter v-if="!withoutPreFooter" />
            <HomeFooter />
        </footer>

        <WelcomeModal />
    </q-layout>
</template>
