import type { HomeBreadCrumbItem } from "~/components/HomeBreadCrumbs.vue"
import { useI18n } from "vue-i18n"
import {
    SUPPORTED_BREAD_CRUMBS_VALUES,
    type SUPPORTED_PAGES,
} from "~/common/breadCrumbs"

export interface HomeBreadCrumbs {
    name: SUPPORTED_PAGES
    breadCrumbs: HomeBreadCrumbItem[]
}

export function useBreadCrumbs(currentPage?: SUPPORTED_PAGES) {
    const i18n = useI18n()
    const parsedBreadCrumbsToLayout = SUPPORTED_BREAD_CRUMBS_VALUES.map(
        (item) => {
            return {
                ...item,
                breadCrumbs: item.breadCrumbs.map((item) => {
                    return {
                        ...item,
                        name: i18n.t(item.name),
                    }
                }),
            }
        }
    )

    function generateBreadCrumbs(
        currentPage?: SUPPORTED_PAGES
    ): HomeBreadCrumbItem[] {
        const breadCrumbs = parsedBreadCrumbsToLayout.find(
            (i) => i.name === currentPage
        )?.breadCrumbs
        return breadCrumbs || parsedBreadCrumbsToLayout[0].breadCrumbs
    }

    const breadCrumbs = ref<HomeBreadCrumbItem[]>(
        currentPage ? generateBreadCrumbs(currentPage) : []
    )
    const setCurrentBreadCrumb = () => {
        breadCrumbs.value = breadCrumbs.value.map((item, index) => {
            if (index == breadCrumbs.value.length - 1) {
                return { ...item, isCurrent: true }
            }
            return { ...item, isCurrent: false }
        })
    }

    const onCreate = () => {
        setCurrentBreadCrumb()
        return breadCrumbs
    }

    const addBreadCrumbsItem = (name: string) => {
        breadCrumbs.value = [...breadCrumbs.value, { name: name }]
        setCurrentBreadCrumb()
    }

    const build = (newBreadCrumbs: HomeBreadCrumbItem[]) => {
        breadCrumbs.value = newBreadCrumbs
        setCurrentBreadCrumb()
    }

    const pushBreadCrumb = (newBreadCrumbsItem: HomeBreadCrumbItem) => {
        breadCrumbs.value.push(newBreadCrumbsItem)
        setCurrentBreadCrumb()
    }

    const updateBreadCrumbs = (
        key: number,
        newBreadCrumbsItem: HomeBreadCrumbItem
    ) => {
        breadCrumbs.value = breadCrumbs.value.map((item, index) => {
            if (index == key) {
                return newBreadCrumbsItem
            }

            return item
        })

        setCurrentBreadCrumb()
    }

    const concatBreadCrumbs = (
        key: number,
        newBreadCrumbsItem: HomeBreadCrumbItem
    ) => {
        if (key === 0) {
            breadCrumbs.value = [newBreadCrumbsItem, ...breadCrumbs.value]
        } else if (key === breadCrumbs.value.length - 1) {
            pushBreadCrumb(newBreadCrumbsItem)
        } else {
            const auxBreadCrumbs = breadCrumbs.value
            const leftBreadCrumbs = auxBreadCrumbs.slice(0, key - 1)
            const rightBreadCrumbs = auxBreadCrumbs.slice(
                key,
                auxBreadCrumbs.length - 1
            )
            breadCrumbs.value = [
                ...leftBreadCrumbs,
                newBreadCrumbsItem,
                ...rightBreadCrumbs,
            ]
        }

        setCurrentBreadCrumb()
    }

    const removeBreadCrumb = (key: number) => {
        breadCrumbs.value = breadCrumbs.value.filter(
            (item, index) => index != key
        )
        setCurrentBreadCrumb()
    }

    return {
        breadCrumbs: onCreate(),
        generateBreadCrumbs,
        build,
        addBreadCrumbsItem,
        setCurrentBreadCrumb,
        updateBreadCrumbs,
        pushBreadCrumb,
        concatBreadCrumbs,
        removeBreadCrumb,
    }
}
