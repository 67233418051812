<script setup lang="ts">
/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const shopListStore = useShopListStore()
const shopSettings = computed(() => {
    console.log(shopListStore.currentShopSettings)
    return shopListStore.currentShopSettings
})
/**
 *
 *  Life cicle events
 *
 *
 */
onMounted(() => {
    shopListStore.inferSettings()
})
/**
 *
 *  Functions section
 *
 *
 */
function openNetwork(url: string) {
    window.open(url)
}
</script>

<template>
    <div v-if="shopListStore.currentShop" class="tw-bg-white tw-w-full">
        <div
            class="max-container tw-w-full tw-flex tw-flex-col tw-py-16 tw-gap-6"
        >
            <h2
                class="tw-font-semibold tw-text-[32px] tw-leading-[48px] tw-text-customGray-700"
            >
                Información sobre la tienda {{ shopListStore.currentShop.name }}
            </h2>
            <p class="tw-text-customGray-500">
                {{ shopListStore.currentShop.description }}
            </p>
            <p
                v-if="shopListStore?.currentShop?.url"
                class="tw-text-customGray-500"
            >
                Para mas información sobre la empresa matriz, consulte el
                siguiente sitio web:
                <a :href="shopListStore?.currentShop?.url">{{
                    shopListStore?.currentShop?.url
                }}</a>
            </p>
            <div
                v-if="shopSettings"
                class="tw-flex tw-flex-col tw-bg-white tw-gap-6 tw-text-customGray-700"
            >
                <q-separator class="tw-text-customGray-200" />
                <div>
                    <q-icon name="fa-solid fa-location-dot" color="gray" />
                    <span class="tw-mx-1">{{ $t("fields.address") }}: </span>
                    <span
                        @click="
                            openNetwork(
                                'http://www.google.com/maps/place/' +
                                    shopSettings?.contacts?.lat +
                                    ',' +
                                    shopSettings?.contacts?.long
                            )
                        "
                    >
                        {{ shopSettings?.contacts?.address }}
                    </span>
                </div>
                <div>
                    <q-icon name="fa-solid fa-phone" color="gray" />
                    <span class="tw-mx-1">{{ $t("fields.phones") }}: </span>
                    {{
                        Array.isArray(shopSettings?.contacts?.phones)
                            ? shopSettings?.contacts?.phones.join(", ")
                            : (shopSettings?.contacts?.phones ?? "")
                    }}
                </div>
                <div>
                    <q-icon name="fa-solid fa-envelope" color="gray" />
                    <span class="tw-mx-1">{{ $t("fields.email") }}: </span>
                    {{ shopSettings?.contacts?.email }}
                </div>
            </div>
            <div
                v-if="shopSettings?.currentShopSettings"
                class="tw-flex tw-flex-col tw-gap-4 tw-px-5"
            >
                <q-separator class="tw-text-customGray-200" />
                <h6 class="tw-text-customGray-500">Horario de atención:</h6>
                <span></span>
            </div>
        </div>
    </div>
</template>

<style>
.prefooter-container::before {
    content: "";
    width: 500px;
    position: absolute;
    left: 0;
    bottom: 0;
    aspect-ratio: 1 / 1;
    background: url("/img/banner-img.svg") no-repeat;
}
</style>
