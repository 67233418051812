<template>
    <div class="tw-flex tw-gap-2">
        <a
            :class="`${getVariantStyles()} ${props.class} tw-cursor-pointer`"
            v-for="(network, i) in contacts?.networks"
            :key="i"
            @click="openNetwork(network.url)"
            v-show="network.url"
        >
            <q-icon
                :name="network.icon"
                :color="color ?? 'inherit'"
                size="16px"
            />
        </a>
    </div>
</template>

<script setup lang="ts">
/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    variant?: string
    color?: string
    class?: string
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const defaultSettingsStore = useDefaultSettingsStore()
const { settings } = storeToRefs(defaultSettingsStore)

const contacts = computed(() => settings.value?.contacts)

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

function getVariantStyles() {
    if (props.variant === "primary") {
        return ""
    }

    // default styles
    return "tw-p-2 tw-bg-white tw-rounded-full tw-w-9 tw-h-9 tw-flex tw-place-items-center tw-place-content-center"
}

function openNetwork(url: string) {
    window.open(url)
}
</script>
