<script setup lang="ts">
import { useDefaultSettingsStore } from "~/stores/defaultSettingsStore"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const router = useRouter()
const defaultSettingsStore = useDefaultSettingsStore()

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

const startBuying = () => {
    defaultSettingsStore.setWelcomeModal(false)
    router.push({ path: "/store" })
}

const close = () => {
    defaultSettingsStore.setWelcomeModal(false)
}
</script>
<template>
    <q-dialog v-model="defaultSettingsStore.welcome_modal" persistent>
        <div
            class="tw-flex tw-flex-col tw-gap-8 tw-bg-white tw-p-4 md:tw-py-4 md:tw-px-8"
        >
            <div class="tw-flex tw-flex-col tw-gap-4">
                <h2 class="tw-text-2xl tw-text-center">
                    "¡Bienvenido a nuestro dapamas.com ! 🚀
                </h2>

                <p class="tw-text-xl tw-mx-auto tw-text-justify">
                    Le presentamos nuestro marketplace en fase de prueba (MVP),
                    por lo que algunas funcionalidades aún están siendo
                    perfeccionadas. Sin embargo, ¡puedes realizar compras de
                    manera segura! Explora nuestros productos, agrega lo que te
                    guste al carrito y haz tu compra de manera sencilla y
                    segura.
                </p>
            </div>
            <div
                class="tw-flex tw-flex-wrap tw-justify-center tw-gap-2 md:tw-gap-12 md:tw-px-8"
            >
                <button
                    @click="startBuying"
                    class="tw-bg-primary tw-w-1/2 md:tw-flex-1 tw-p-2 tw-rounded-full tw-text-white"
                >
                    Comenzar a comprar
                </button>
                <button
                    @click="close"
                    class="tw-bg-secondary tw-p-2 tw-w-1/2 md:tw-flex-1 tw-rounded-full tw-text-white"
                >
                    Cerrar
                </button>
            </div>
        </div>
    </q-dialog>
</template>
